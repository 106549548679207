import { render, staticRenderFns } from "./20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue?vue&type=template&id=74257f57"
import script from "./20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue?vue&type=script&lang=js"
export * from "./20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports